<!-- 添加发票 -->
<template>
  <div class="flex-col page">

    <div class="flex-col">
      <div class="flex-row justify-between group_5">
        <div class="flex-row group_6">
          <span class="font_1 text_3">当前位置：学员信息</span>
          <img class="image_5"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16629786958662112914.png" />
          <span class="font_1 text_4">开票信息</span>
          <img class="image_5"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16629786958662112914.png" />
          <span class="font_1 text_5">添加开票信息</span>
        </div>
        <div class="flex-row toback" @click="gobackBtn">
          <img class="image_4"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994929819924446.png" />
          <span class="font_1 text_6">返回</span>
        </div>
      </div>
      <div class="flex-col items-start space-y-20 section_3">
        <div class="flex-row  space-x-14 group_7">
          <span class="font_2 text_7">开票类型：</span>
          <div class="flex-col items-center section_4" :class="[invoice_id1==1?'active':'']">
            <span class="text_8 text_9" :data-invoice_id1="1">电子发票</span>
          </div>
        </div>
        <div class="flex-row  group_8">
          <span class="font_2 text_10">开票类型：</span>
          <div v-if="invoice_id1 == 1" class="flex-row">
            <div class="flex-col items-center section_5" :class="[invoice_id2==1?'active':'']">
              <span class="text_8 text_11" :data-invoice_id2="1" @click="selectFapiaoTypeBtn($event)">普通发票(个人)</span>
            </div>
            <div class="flex-col items-center section_5" :class="[invoice_id2==2?'active':'']">
              <span class="text_8 text_11" :data-invoice_id2="2" @click="selectFapiaoTypeBtn($event)">普通发票(单位)</span>
            </div>
            <div class="flex-col items-center section_5" :class="[invoice_id2==3?'active':'']">
              <span class="text_8 text_11" :data-invoice_id2="3" @click="selectFapiaoTypeBtn($event)">专用发票(单位)</span>
            </div>
          </div>
         <!-- <div v-else class="flex-row">
            <div class="flex-col items-center section_5" :class="[invoice_id2==3?'active':'']">
              <span class="text_8 text_11" :data-invoice_id2="3" @click="selectFapiaoTypeBtn($event)">单位</span>
            </div>
          </div> -->
        </div>
        <div class="flex-row space-x-12 group_9" v-if="invoice_id2 == 1">
          <div class="group_10">
            <span class="font_4">*</span>
            <span class="font_2">姓名：</span>
          </div>
          <div class="flex-col items-start text-wrapper_3">
            <input class="font_3 text_13" v-model="name" name="name" placeholder="请输入姓名">
          </div>
        </div>

        <div class="flex-row space-x-12 group_9" v-if="invoice_id2 == 2 || invoice_id2 == 3">
          <div class="group_10">
            <span class="font_4">*</span>
            <span class="font_2">单位名称：</span>
          </div>
          <div class="flex-col items-start text-wrapper_3">
            <input class="font_3 text_13" v-model="company" name="company" placeholder="请输入单位名称">
          </div>
        </div>
        <div class="flex-row space-x-12 group_9" v-if="invoice_id2 == 2 || invoice_id2 == 3">
          <div class="group_10">
            <span class="font_4">*</span>
            <span class="font_2">纳税人识别号：</span>
          </div>
          <div class="flex-col items-start text-wrapper_3">
            <input class="font_3 text_13" v-model="taxpayer_number" name="taxpayer_number" placeholder="请输入纳税人识别号">
          </div>
        </div>

        <div class="flex-row space-x-12 group_9" v-if="invoice_id2 == 1 || invoice_id2 == 2 || invoice_id2 == 3">
          <div class="group_10">
            <span class="font_4">*</span>
            <span class="font_2">邮箱：</span>
          </div>
          <div class="flex-col items-start text-wrapper_3">
            <input class="font_3 text_13" v-model="email" name="email" placeholder="请输入接收邮箱">
          </div>
        </div>
        <div class="flex-row space-x-12 group_9" v-if="invoice_id2 == 3">
          <div class="group_10">
            <span class="font_4">*</span>
            <span class="font_2">单位地址：</span>
          </div>
          <div class="flex-col items-start text-wrapper_3">
            <input class="font_3 text_13" v-model="company_address" name="company_address" placeholder="请输入单位地址">
          </div>
        </div>
        <div class="flex-row space-x-12 group_9" v-if="invoice_id2 == 3">
          <div class="group_10">
            <span class="font_4">*</span>
            <span class="font_2">单位联系电话：</span>
          </div>
          <div class="flex-col items-start text-wrapper_3">
            <input class="font_3 text_13" v-model="company_tel" name="company_tel"
              placeholder="请填写 手机号：13xxxxxxxxxx（11位数）或座机：0898xxxxxxxx">
          </div>
        </div>
        <div class="flex-row space-x-12 group_9" v-if="invoice_id2 == 3">
          <div class="group_10">
            <span class="font_4">*</span>
            <span class="font_2">开户行：</span>
          </div>
          <div class="flex-col items-start text-wrapper_3">
            <input class="font_3 text_13" v-model="bank" name="bank" placeholder="请填写单位开户行名称">
          </div>
        </div>
        <div class="flex-row space-x-12 group_9" v-if="invoice_id2 == 3">
          <div class="group_10">
            <span class="font_4">*</span>
            <span class="font_2">银行账号：</span>
          </div>
          <div class="flex-col items-start text-wrapper_3">
            <input class="font_3 text_13" v-model="bank_account" name="bank_account" placeholder="请填写单位开户行银行账号">
          </div>
        </div>
        <div class="flex-row space-x-12 group_9" v-if="invoice_id2 == 1 || invoice_id2 == 2 || invoice_id2 == 3">
          <div class="group_10">
            <span class="font_2 text_14"> 备注：</span>
          </div>
          <div class="flex-col items-start text-wrapper_3">
            <input class="font_3 text_13" v-model="remarks" name="remarks" placeholder="请填写备注">
          </div>
        </div>

        <div class="flex-col items-center text-wrapper_4" @click="tijiaoBtn"><span class="font_2 text_16">确认添加</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        invoice_id1: 1, //开票类型
        invoice_id2: 1, //发票类型
        name: '',
        email: '',
        remarks: '',
        company: '',
        taxpayer_number: '',
        company_address: '',
        company_tel: '',
        bank: '',
        bank_account: '',
        identity:sessionStorage.getItem('identity')
      };
    },
    created() {
      let that = this;
      that.ordersn = that.$route.query.ordersn;
    },
    methods: {
      //返回
      gobackBtn() {
        let that = this;
		if(that.identity == 1){
			that.$router.push({
			  name: 'companyOrderDetail',
			  query: {
			    ordersn: that.ordersn
			  }
			});
		}else{
			that.$router.push({
			  name: 'userOrderDetail',
			  query: {
			    ordersn: that.ordersn
			  }
			});
		}
        
      },

      //选择开票类型
      selectFapiaoTypeBtn(e) {
        let that = this;
        that.invoice_id2 = e.target.dataset.invoice_id2;
      },
      tijiaoBtn() {
        let that = this;
        let param = {};
        switch (parseInt(that.invoice_id2)) {
          case 1:
            param = {
              invoice_id1: that.invoice_id1,
              invoice_id2: that.invoice_id2,
              name: that.name,
              email: that.email,
              remarks: that.remarks,
            };

            if (!param.name) {
              let that = this;
              that.$myMessage.warning('姓名不能为空');
              return false;
            }
            if (!param.email) {
              let that = this;
              that.$myMessage.warning('接收邮箱不能为空');
              return false;
            }

            break;
          case 2:
            param = {
              invoice_id1: that.invoice_id1,
              invoice_id2: that.invoice_id2,
              company: that.company,
              taxpayer_number: that.taxpayer_number,
              email: that.email,
              remarks: that.remarks,
            };

            if (!param.company) {
              let that = this;
              that.$myMessage.warning('单位名称不能为空');
              return false;
            }
            if (!param.taxpayer_number) {
              let that = this;
              that.$myMessage.warning('纳税人识别号不能为空');
              return false;
            }
            if (!param.email) {
              let that = this;
              that.$myMessage.warning('接收邮箱不能为空');
              return false;
            }

            break;
          case 3:
            param = {
              invoice_id1: that.invoice_id1,
              invoice_id2: that.invoice_id2,
              company: that.company,
              taxpayer_number: that.taxpayer_number,
              company_address: that.company_address,
              company_tel: that.company_tel,
              bank: that.bank,
              bank_account: that.bank_account,
              email: that.email,
              remarks: that.remarks,
            };

            if (!param.company) {
              let that = this;
              that.$myMessage.warning('单位名称不能为空');
              return false;
            }
            if (!param.taxpayer_number) {
              let that = this;
              that.$myMessage.warning('纳税人识别号不能为空');
              return false;
            }
            if (!param.email) {
              let that = this;
              that.$myMessage.warning('接收邮箱不能为空');
              return false;
            }
            // if (param.email != '') {
            //   let that = this;
            //   that.$myMessage.warning('接收邮箱格式不正确');
            //   return false;
            // }
            if (!param.company_address) {
              let that = this;
              that.$myMessage.warning('单位地址不能为空');
              return false;
            }
            if (!param.company_tel) {
              let that = this;
              that.$myMessage.warning('单位联系电话不能为空');
              return false;
            }
            if (!param.bank) {
              let that = this;
              that.$myMessage.warning('开户行不能为空');
              return false;
            }
            if (!param.bank_account) {
              let that = this;
              that.$myMessage.warning('银行账号不能为空');
              return false;
            }
            break;
          default:
            that.$myMessage.warning('请选择发票类型!');
            return false;
        }

        that.$request.addUserInvoice(param).then((res) => {
          if (res.code == 200) {
            that.$myMessage.success(res.msg);
            setTimeout(function() {
              if(that.identity == 1){
					that.$router.push({
					  name: 'companyOrderDetail',
					  query: {
						ordersn: that.ordersn
					  }
					});
				}else{
					that.$router.push({
					  name: 'userOrderDetail',
					  query: {
						ordersn: that.ordersn
					  }
					});
				}
            }, 1000);
          }
        })
      },
    },
  };
</script>

<style scoped lang="css">
  .page {
    background-color: #f5f6fa;
    width: calc(100% - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 1.25rem 1.25rem 1.88rem;
  }

  .toback {
    cursor: pointer;
  }

  .section {
    padding: 0.75rem 1.25rem 0 3.19rem;
    background-color: #ffffff;
  }

  .group {
    padding-bottom: 0.81rem;
    overflow: hidden;
    width: 15.56rem;
    height: 3.69rem;
  }

  .image {
    flex-shrink: 0;
    width: 2.88rem;
    height: 2.88rem;
  }

  .text_2 {
    margin-top: 1.06rem;
    flex-shrink: 0;
    color: #3d3d3d;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.41rem;
  }

  .group_2 {
    margin-top: 0.19rem;
    overflow: hidden;
    width: 24.31rem;
    height: 2.5rem;
  }

  .group_3 {
    overflow: hidden;
    width: 16.88rem;
    height: 2.5rem;
  }

  .space-x-16>*:not(:first-child) {
    margin-left: 1rem;
  }

  .image_2 {
    flex-shrink: 0;
    width: 2.5rem;
    height: 2.5rem;
  }

  .text {
    flex-shrink: 0;
    align-self: center;
    color: #555555;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    line-height: 1.03rem;
  }

  .section_2 {
    align-self: center;
    background-color: #d8d8d8;
    width: 0.094rem;
    height: 0.81rem;
  }

  .image_3 {
    align-self: center;
    width: 1.13rem;
    height: 1.13rem;
  }

  .group_4 {
    padding: 0 1.25rem 15.94rem 17.19rem;
  }

  .group_5 {
    padding: 1.56rem 1.38rem 1.56rem 1.63rem;
  }

  .group_6 {
    margin: 0.38rem 0 0.25rem;
  }

  .font_1 {
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
    color: #3d3d3d;
  }

  .text_3 {
    margin-bottom: 0.13rem;
  }

  .image_5 {
    flex-shrink: 0;
    width: 0.88rem;
    height: 0.88rem;
  }

  .text_4 {
    margin-bottom: 0.13rem;
  }

  .text_5 {
    margin-bottom: 0.13rem;
  }

  .image_4 {
    flex-shrink: 0;
    width: 1.56rem;
    height: 1.56rem;
  }

  .text_6 {
    margin: 0.38rem 0 0.38rem 0.25rem;
    color: #0074ff;
    line-height: 0.78rem;
  }

  .section_3 {
    padding: 1.25rem 1.19rem 15.31rem;
    background-color: #ffffff;
    border-radius: 0.63rem;
  }

  .space-y-20>*:not(:first-child) {
    margin-top: 1.25rem;
  }

  .group_7 {
    width: 21.13rem;
  }

  .font_2 {
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
    color: #3d3d3d;
  }

  .text_7 {
    align-self: center;
    width: 120px;
    text-align: right;
  }

  .section_4 {
    padding: 1rem 0 1.06rem;
    background-color: #ffffff;
    border: solid 0.063rem #dcdfe6;
    border-radius: 0.63rem;
    width: 7.13rem;
    height: 3.13rem;
    position: relative;
    cursor: pointer;
  }

  .active {
    border: solid 0.063rem #0074ff !important;
    color: #0074ff !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAcCAMAAAA+9+1qAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAADhUExURQAAABSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/////yGY/02t/4HE/3/E/8rn/9zv/8rm/4/L/3zC/yaa/0mr//7//1Gu/8Lj/1my/268/3W//+73/+Xz/9nt//f7/6bW/x+X/93v/2e4/6jX/0Kn/1Ov/0ap/zmj/9Lq/yqc/5XO/63Z//n8/7rf/y+e//P5/+Ly/5HM/zOh//T6/8Di/+r1/8Pj/x2W/9Hq/45fPE0AAAAadFJOUwAyIOy13n8CCP33YsxImxITnEnj2OQRkRCSfiet/QAAANlJREFUOMuF0ddawkAUhdFAKKGKdM9GQVB6R0EBpdf3fyDiR4RhJuTsi1yti/xnNI2dEeWNO0ysiejEIb8vQBx68BBxyOUlFoWCxCEjSsQhs5xFf+UMOpc7I6vcEf2XO6FL+X0klFO1+GKLxPLhAK92SCxfj4GSiqzy5/y7+Z3vgc++gmJWeaHSO1DuB9hOlB+/lNeAaW4FHL+Vumt5uY3f2QbLL/kExqNwl3oLwG4k30ksN9foYvEhHzOuSzd+6zSli0tvriwllN9dxubN5SXTNm9+u8RTVjsBbU82g3zDdeMAAAAASUVORK5CYII=) no-repeat 100% 100%;
  }

  .image_6 {
    width: 2rem;
    height: 1.5rem;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .text_8 {
    position: relative;
  }

  .text_9 {
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .text-wrapper {
    padding: 1rem 0 1.06rem;
    background-color: #ffffff;
    border-radius: 0.63rem;
    width: 7.13rem;
    height: 3.13rem;
    border: solid 0.063rem #dcdfe6;
  }

  .group_8 {
    width: 26.5rem;
  }

  .space-x-14>*:not(:first-child) {
    margin-left: 0.88rem;
  }

  .text_10 {
    align-self: center;
    width: 120px;
    text-align: right;
  }

  .section_5 {
    margin-left: 0.88rem;
    padding: 1rem 0;
    background-color: #ffffff;
    border-radius: 0.63rem;
    width: 9.81rem;
    height: 3.13rem;
    border: solid 0.063rem #dcdfe6;
    position: relative;
    cursor: pointer;
  }

  .text_11 {
    /* color: #0074ff; */
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 1.03rem;
  }

  .text-wrapper_2 {
    padding: 1rem 0;
    background-color: #ffffff;
    border-radius: 0.63rem;
    width: 9.81rem;
    height: 3.13rem;
    border: solid 0.063rem #dcdfe6;
  }

  .text_12 {
    line-height: 1.03rem;
  }

  .group_9 {
    /* margin-left: 1.75rem; */
    width: 37.88rem;
  }

  .group_10 {
    align-self: center;
    line-height: 0.94rem;
    height: 0.94rem;
    width: 120px;
    text-align: right;
  }

  .font_4 {
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.28rem;
    color: #e21313;
  }

  .text-wrapper_3 {
    flex: 1 1 auto;
    padding: 0.49rem 0 0.75rem;
    background-color: #ffffff;
    border-radius: 0.31rem;
    width: 33.63rem;
    height: 2.5rem;
    border: solid 0.063rem #dcdfe6;
  }

  .font_3 {
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
    color: #3d3d3d;
  }

  .text_13 {
    margin-left: 0.88rem;
    width: 90%;
    border: none;
    outline: none;
  }

  .space-x-10>*:not(:first-child) {
    margin-left: 0.63rem;
  }

  .group_11 {
    margin-left: 2.13rem;
    width: 37.47rem;
  }

  .space-x-12>*:not(:first-child) {
    margin-left: 0.75rem;
  }

  .text_14 {
    align-self: center;
  }

  .text_15 {
    margin-left: 0.88rem;
  }

  .text-wrapper_4 {
    margin-left: 5.94rem;
    padding: 0.75rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 6rem;
    cursor: pointer;
  }

  .text_16 {
    color: #ffffff;
  }
</style>
